import { type AppManifest } from '@wix/app-manifest-builder';
/**
 * This is the interface for the object (or resolved object) to be returned from the `getAppManifest` function.
 */
export default AppManifest;
export { AppManifestBuilder } from '@wix/app-manifest-builder';

interface GfppDesktopConfig {
  /**
   * Override the first main textual action
   */
  mainAction1?: EditableGfppLabelButtonConfig;
  /**
   * Override the second textual action
   */
  mainAction2?: EditableGfppLabelButtonConfig;
  /**
   * Override the icon buttons
   */
  iconButtons: {
    settings?: EditableGfppIconButtonConfig;
    design?: EditableGfppIconButtonConfig;
    crop?: GfppButtonConfig;
    filters?: GfppButtonConfig;
    animation?: GfppButtonConfig;
    link?: GfppButtonConfig;
    stretch?: GfppButtonConfig;
    layout?: EditableGfppIconButtonConfig;
    upgrade?: ActionDefinition;
    connect?: GfppButtonConfig;
    pinMode?: GfppButtonConfig;
  };
  /**
   * The help ID for the connected component.
   */
  helpId?: string;
}

interface GfppMobileConfig {
  /**
   * Override the first main textual action
   */
  mainAction1?: EditableGfppLabelButtonConfig;
  /**
   * Override the second textual action
   */
  mainAction2?: EditableGfppIconButtonConfig;
  /**
   * Override the icon buttons
   */
  iconButtons: {
    textSize?: GfppButtonConfig;
    settings?: EditableGfppIconButtonConfig;
    design?: EditableGfppIconButtonConfig;
    crop?: GfppButtonConfig;
    animation?: GfppButtonConfig;
    layout?: EditableGfppIconButtonConfig;
    hide?: GfppButtonConfig;
    upgrade?: ActionDefinition;
  };
  /**
   * The help ID for the connected component.
   */
  helpId?: string;
}

interface ComponentStageBehavior {
  /**
   * Defines whether the component can be duplicated (default is `true`).
   * When set to `false` for the component:
   * - Cut/Copy menu items don't appear in the component's Right Click Menu
   * - Cut/Copy keyboard shortcuts don't work for this component
   * - Cut/Copy actions are greyed out in the toolbar
   */
  duplicatable?: boolean;

  /**
   * Defines whether the component can be rotated (default is `true`).
   * When set to `false` for the component:
   * - The rotate handle does not appear when it's selected
   * - The rotation degrees input in the toolbar is greyed out
   */
  rotatable?: boolean;

  /**
   * Specifies whether the component can be pinned to an area of the screen or unpinned from it. (default is `true`)
   */
  pinnable?: boolean;
  /**
   * Specifies whether showOnAllPages can be toggled for this component (default is `true`)
   */
  toggleShowOnAllPagesEnabled?: boolean;

  /**
   * Defines whether the component can be resized (default is `true`).
   */
  resizable?: boolean;

  /**
   * Defines whether the component can be re-parented, meaning can be grouped/un-grouped or attached/detached from parent (default is `true`).
   */
  canReparent?: boolean;
}

interface ConnectedComponentConfig {
  /**
   * Data Influencing the GFPP and Right Click Menu of the connected component.
   */
  gfpp?: {
    /**
     * Override the GFPP in desktop view
     */
    desktop?: GfppDesktopConfig;
    /**
     * Override the GFPP in mobile view
     */
    mobile?: GfppMobileConfig;
  };

  /**
   * Specifies behavior when deleting a component connected with this role.
   * - `false`: allow deleting the components without confirmation (default)
   * - `true`: show a confirmation dialog with the default values before deleting the component
   * - Object: Specify custom text for the title and content of the confirmation dialog.
   */
  confirmOnDelete?:
    | boolean
    | {
        title: string;
        contentText: string;
      };

  /**
   * Specifies the connected component's behaviors on stage.
   * Note: Stage behaviors can only be limited from the manifest, not extended.
   * E.g.: The "Facebook Like" component is not rotatable.
   * Setting `rotatable` to `true` for a connected "Facebook Like" component will not change this.
   */
  behavior?: ComponentStageBehavior;

  /**
   * Specifies the display name of the component once it's connected
   * Displayed in the component label on hover in the editor.
   */
  displayName?: string;
}

/**
 * The stage data for controllers by type and state.
 */
interface ControllerStateStageData {
  /**
   * Config for the main textual for the controller
   */
  mainAction?: {
    /**
     * The label for the main textual action for a controller type and state.
     */
    label: string;
  };

  /**
   * Determines when the controller is visible.
   */
  visibility?: ControllerVisibility;
  connections?: {
    /**
     * The stage data for each type of role connected to the controller of the specified type and state.
     */
    [index: string]: ConnectedComponentConfig;

    /**
     * role type '*' is reserved and will be used as default in case behavior for the specific role is not defined
     */
    '*'?: ConnectedComponentConfig;
  };

  /**
   * The URL of the controller type and state's icon image in the editor.
   * Should be a 48px x 48px vector image.
   */
  icon?: string;

  /**
   * Specifies whether showOnAllPages can be toggled for this controller (default is `true`)
   * Note: this property used to be called `enableShowOnAllPages`, but the latter is deprecated.
   */
  toggleShowOnAllPagesEnabled?: boolean;

  /**
   * Specifies whether the controller can be removed (default is `true`)
   */
  removable?: boolean;

  /**
   * Specifies whether the controller can be duplicated (default is `true`)
   */
  duplicatable?: boolean;
}

interface WidgetStateStageData {
  connections?: {
    /**
     * The stage data for each type of role connected to the controller of the specified type and state.
     */
    [index: string]: ConnectedComponentConfig;

    /**
     * role type '*' is reserved and will be used as default in case behavior for the specific role is not defined
     */
    '*'?: ConnectedComponentConfig;
  };

  /**
   * Specifies the widget's behavior on Stage.
   * Note: Stage behaviors can only be limited from the manifest, not extended.
   */
  behavior?: ComponentStageBehavior;

  gfpp?: {
    /**
     * Override the component's gfpp in desktop view
     */
    desktop: GfppDesktopConfig;
    /**
     * Override the component's gfpp in mobile view
     */
    mobile: GfppMobileConfig;
  };

  /**
   * Specifies the widget's display name
   * Displayed in the component label on hover in the editor, and when interacting with its children.
   */
  displayName?: string;
}

export enum ControllerVisibility {
  /**
   * Controllers are visible in Developer Mode (default).
   */
  DEV = 'DEV',

  /**
   * Controllers are never visible.
   */
  NEVER = 'NEVER',
}

/**
 * Configuration for a GFPP action.
 * - `hide`: The action is hidden
 * - `default`: If the action exists for the component, the button will be shown and have the default behaviour.
 */
export enum GfppButtonConfig {
  /**
   * The GFPP button and Right Click Menu action are hidden
   */
  HIDE = 'HIDE',

  /**
   * The GFPP button an Right Click Menu action are default behavior
   */
  DEFAULT = 'DEFAULT',
}

/**
 * Configuration for a customizable main action.
 * - `hide`: The action is hidden
 * - `default`: If the action exists for the component, the button will be shown and have the default behaviour.
 * - Object: Clicking this button will send a `componentGfppClicked` event to the app's worker script.
 */
type EditableGfppLabelButtonConfig = GfppButtonConfig | MainActionDefinition;

/**
 * Configuration for a customizable icon action.
 * * - `hide`: The action is hidden
 * - `default`: If the action exists for the component, the button will be shown and have the default behaviour.
 * - Object: Clicking this button will send a `componentGfppClicked` event to the app's worker script.
 */
type EditableGfppIconButtonConfig = GfppButtonConfig | ActionDefinition;

/**
 * Defines any action overriden from the GFPP and Right Click Menu
 */
interface ActionDefinition {
  /**
   * The action ID to be send in the `componentGfppClicked` event sent to the editor
   */
  actionId: string;
}

/**
 * Defines a textual main action for the component.
 */
interface MainActionDefinition extends ActionDefinition {
  /**
   * The label to be shown on the GFPP button and Right Click Menu option
   */
  label: string;
}

interface PagesManifest {
  /**
   * Specifies the actions that appear for the cogwheel for application pages in the developer tools pages tree.
   */
  pageActions?: {
    default: {
      [index: number]: {
        /**
         * The eventType to be sent to the app
         */
        event?: string;
        /**
         * The text to appear for the action in the context menu in the pages tree
         */
        title?: string;
        /**
         * The icon to appear for the action in the context menu in the pages tree
         * @default 'file'
         */
        icon?: string;
        type?: string;
      };
    };
  };
  /**
   * Specifies the tabs and context menu items that appear for a router page in the pages panel
   */
  pageSettings?: {
    default: {
      [index: number]: {
        /**
         * The title for the tab in the pages panel
         */
        title: string;
        /**
         * The help id that will be open when the user chooses ?
         */
        helpId?: string;
        /**
         * The tab IFrame URL
         */
        url?: string;
        /**
         * The type of the action
         */
        type: PageSettingsTab;
        event?: string;
        icon?: string;
      };
    };
  };
  /**
   * Specifies the action to be displayed for the router itself in the context menu, in the pages panel and tree
   */
  applicationActions?: {
    default: {
      [index: number]: {
        /**
         * The title of the action
         */
        title: string;
        /**
         * The icon to of the action
         */
        icon: string;
        /**
         * The event type to be sent for the action
         */
        event: string;
        /**
         * The event type to be sent for the action
         */
        type: string;
      };
    };
  };
  applicationSettings?: {
    default: {
      /**
       * Specifies label to be used to define pages group in the pages panel or site structure
       */
      displayName: string;
      /**
       * Specifies id of help article to be shown at the pages view
       */
      helpId: string;
    };
    /**
     * Specifies the content should be shown if there are no pages related to application
     */
    emptyState?: {
      /**
       * Url to load when state is empty
       */
      url: string;
      /**
       * Help id of article explaining application empty state
       */
      helpId?: string;
    };
  };
}

interface RoutersManifest {
  /**
   * Specifies settings of router
   */
  routerSettings?: {
    /**
     * Specifies label to be used to define router in the pages panel or site structure
     */
    displayName: string;
    /**
     * Specifies id of help article to be shown at the router pages view
     */
    helpId: string;
    /**
     * Specifies what content should be shown if there are no pages related to application
     */
    emptyState?: {
      /**
       * Url to load when state is empty
       */
      url: string;
      /**
       * Help id of article explaining router empty state
       */
      helpId?: string;
    };
  };
  /**
   * DEPRECATED
   * Specifies settings for each pagesGroup, indexed by the routerConfig group
   */
  pagesGroups?: {
    [index: string]: {
      /**
       * The label to be displayed in the pagesPanel
       */
      label?: string;
    };
  };
  /**
   * Specifies the actions that appear for the cogwheel for router in the developer tools pages tree and pages panel
   */
  pageActions?: {
    default: {
      [index: number]: {
        /**
         * The eventType to be sent to the app
         */
        event: string;
        /**
         * The text to appear for the action in the context menu in the pages tree
         */
        title?: string;
        /**
         * The icon to appear for the action in the context menu in the pages tree
         * @default 'file'
         */
        icon?: string;
        /**
         * Semantic type of action to give a clue to editor what's the action's meaning
         */
        type?: PageActionsEvent;
      };
    };
  };
  /**
   * Specifies the tabs that appear for a router page in the page settings panel
   */
  pageSettings?: {
    default: {
      [index: number]: {
        /**
         * The title for the tab in the pages panel
         */
        title: string;
        /**
         * The help id that will be open when the user chooses ?
         */
        helpId: string;
        /**
         * The tab IFrame URL
         */
        url: string;
        /**
         * Semantic type of action to give a clue to editor what's the action's meaning
         */
        type: PageSettingsTab;
        icon?: string;
        event?: string;
      };
    };
  };
  /**
   * Specifies the action to be displayed for the router itself in the context menu, in the pages panel and tree
   */
  routerActions?: {
    default: {
      [index: number]: {
        /**
         * The title of the action
         */
        title: string;
        /**
         * The icon to of the action
         */
        icon: string;
        /**
         * The event type to be sent for the action
         */
        event: string;
        /**
         * Specifies whether the router actions should be shown outside of developer mode
         */
        showOnAllModes?: boolean;
        type?: RouterActionsType;
      };
    };
  };
}

export enum PageSettingsTab {
  LAYOUT = 'layout',
  PERMISSIONS = 'permissions',
  PAGE_INFO = 'page_info',
  SEO = 'seo',
  SOCIAL_SHARE = 'social_share',
}

export enum EventType {
  APP = 'appEvent',
  PAGE = 'pageEvent',
}

export enum PageActionsEvent {
  ADD = 'page_add',
  RENAME = 'page_rename',
  REMOVE = 'page_remove',
  APP_ACTION = 'appActionClicked',
}

export enum AppManagerEvent {
  OPEN_DASHBOARD = 'open_dashboard',
  OPEN_CREATE_CHALLENGE = 'open_create_challenge',
  OPEN_SP = 'open_challenges_sp',
}

export enum AppActionEvent {
  ADD_PAGE = 'addPage',
  DELETE = 'delete_app',
}
export enum RouterActionsType {
  /**
   * Action with this type can be treated by editor in a special way (like connecting it to button on the footer of pages panel)
   * There maybe only one like this
   */
  ADD_PAGE = 'add_page',
}
