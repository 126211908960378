import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { TPARef } from '../types/common';
import EditorWrapper from '../EditorWrapper';
import { PARTICIPANT_PAGE_ID } from '../app-config';

function getEditorType(flowAPI: EditorScriptFlowAPI) {
  const { isClassicEditor, isEditorX } = flowAPI.environment;
  if (isClassicEditor) {
    return 'Classic';
  }

  if (isEditorX) {
    return 'EditorX';
  }

  return 'Studio';
}

export async function installParticipantPage(
  flowAPI: EditorScriptFlowAPI,
  editorWrapper: EditorWrapper,
  programPagesOnStage: TPARef[],
): Promise<void> {
  const participantPage = await editorWrapper.getTpaSection(
    PARTICIPANT_PAGE_ID,
    programPagesOnStage,
  );

  if (participantPage) {
    return Promise.resolve();
  }

  flowAPI.fedops.interactionStarted('install-page-editor');

  try {
    await editorWrapper.addPage(PARTICIPANT_PAGE_ID);
    await editorWrapper.save();

    flowAPI.fedops.interactionEnded('install-page-editor');

    return Promise.resolve();
  } catch (error) {
    console.error('Cannot install Participant Page:', error);

    flowAPI.errorMonitor.captureException(error, {
      tags: {
        installParticipantPage: true,
        editorType: getEditorType(flowAPI),
      },
    });

    return Promise.resolve();
  }
}
